import React from "react"
import { graphql } from "gatsby"
import SiteMetadata from "../components/SiteMetadata"
import Layout from "../layouts/Layout"
;

const HowToResetFirmwarePage = ({ data, location}) => {
 
  return (
    <Layout data={data.meta} location={location}>
      <SiteMetadata title="How To Reset Firmware" />
      <div className="container h-screen py-32 mx-auto text-center">
        <h1 className="text-5xl lg:text-7xl">How To Reset Firmware</h1>
      </div>
    </Layout>
  )
}

export default HowToResetFirmwarePage

export const query = graphql`
  query HowToResetFirmwareEnQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`